<ng-container>
  <header class="p-4 pb-0 flex flex-row justify-between items-center">
    <ng-container *ngIf="!busy; else loadingState">
      <ion-chip
        *ngIf="!emptyChart"
        (click)="toggleModal()"
        [color]="+perfDetail!.attributes.performanceValue >= 0  ? 'success' : 'danger'"
      >
        <ion-icon
          [src]="+perfDetail!.attributes.performanceValue >= 0 ? 'assets/icons/arrow_up.svg' : 'assets/icons/arrow_down.svg'"
          [color]="+perfDetail!.attributes.performanceValue >= 0 ? 'success' : 'danger'"
        ></ion-icon>
        <ion-label>{{ perfDetail!.attributes.performanceValue || 0 | percent : "1.0-2" : "fr" }}</ion-label>
        <ion-icon name="information-circle-outline"></ion-icon>
      </ion-chip>
      <app-sheet-modal
        [(isModalOpen)]="isModalVisible"
        [title]="'WALLET.PERFORMANCE.TITLE' | transloco"
      >
        <ion-chip
          *ngIf="!emptyChart"
          class="ml-4"
          [color]="+perfDetail!.attributes.performanceValue >= 0  ? 'success' : 'danger'"
        >
          <ion-icon
            [src]="+perfDetail!.attributes.performanceValue >= 0 ? 'assets/icons/arrow_up.svg' : 'assets/icons/arrow_down.svg'"
            [color]="+perfDetail!.attributes.performanceValue >= 0 ? 'success' : 'danger'"
          ></ion-icon>
          <ion-label>{{ perfDetail!.attributes.performanceValue || 0 | percent : "1.0-2" : "fr" }}</ion-label>
        </ion-chip>
        <p class="text-gray-500 m-4 whitespace-pre-wrap">{{ 'WALLET.PERFORMANCE.DESCRIPTION' | transloco }}</p>
      </app-sheet-modal>
    </ng-container>
    <ng-template #loadingState>
      <ion-chip>
        <ion-skeleton-text [animated]="true" class="h-3 w-16"></ion-skeleton-text>
      </ion-chip>
    </ng-template>
    <ion-card color="light" class="m-0 px-4 shadow-none">
      <ion-select
        interface="action-sheet"
        aria-label="timeRange"
        [value]="timeRangeList[0]"
        [formControl]="timeRangeSelectionControl"
        class="w-auto"
      >
        <ion-select-option *ngFor="let timeRange of this.performanceTableTimeRange" [value]="timeRange.attributes.periodType">{{ "WALLET.GRAPH." + timeRange.attributes.periodType | transloco }}</ion-select-option>
      </ion-select>
    </ion-card>
  </header>
  <ion-card class="text-center mr-0 h-60 bg-transparent shadow-none w-full">
      <div id="walletChart" [class]="{ hidden: emptyChart || busy }" class="h-full touch-none" #walletChart></div>
      <p [class]="{ hidden: !emptyChart || busy }" class="py-16">{{ "WALLET.GRAPH.EMPTY" | transloco }}</p>
      <ion-spinner [class]="{ hidden: !busy }" class="py-16"></ion-spinner>
  </ion-card>
</ng-container>
