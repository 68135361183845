
<ion-app>

  <ion-split-pane when="md" contentId="main">
    <ion-menu [disabled]="!isMdScreen" type="reveal" contentId="main" [hidden]="!user">
      <app-desktop-sidepanel *ngIf="user"></app-desktop-sidepanel>
    </ion-menu>

    <div class="ion-page" id="main">
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
</ion-app>
