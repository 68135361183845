import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserHeaderComponent } from "./user-header/user-header.component";
import { IonicModule } from "@ionic/angular";
import { WalletValueSectionComponent } from "./wallet-value-section/wallet-value-section.component";
import { EvolutionCardComponent } from "./evolution-card/evolution-card.component";
import { WalletActionsSectionComponent } from "./wallet-actions-section/wallet-actions-section.component";
import { ContactCardComponent } from "./contact-card/contact-card.component";
import { TranslocoPipe } from "@ngneat/transloco";
import { ItemListComponent } from "./item-list/item-list.component";
import { ReactiveFormsModule } from "@angular/forms";
import { WalletPerformanceTableComponent } from './wallet-performance-table/wallet-performance-table.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { WalletCompositionChartComponent } from './wallet-composition-chart/wallet-composition-chart.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { BaseCardComponent } from './base-card/base-card.component';
import { BuildCmcUrlPipe } from '../core/pipes/buildCmcUrl/build-cmc-url.pipe';
import { BaseListComponent } from './base-list/base-list.component';
import { RouterModule} from '@angular/router';
import { DesktopSidepanelComponent } from "./desktop-sidepanel/desktop-sidepanel.component";
import { SheetModalComponent } from './sheet-modal/sheet-modal.component';
import { WalletSelectorComponent } from './wallet-selector/wallet-selector.component';
import { FormsModule } from '@angular/forms';
import {ErrorControlPipe} from "../core/pipes/error-control/error-control.pipe";
import { AccountNumberComponent } from './account-number/account-number.component';

@NgModule({
  declarations: [
    UserHeaderComponent,
    WalletValueSectionComponent,
    EvolutionCardComponent,
    WalletActionsSectionComponent,
    ContactCardComponent,
    ItemListComponent,
    WalletPerformanceTableComponent,
    BaseListComponent,
    ActivityFeedComponent,
    WalletCompositionChartComponent,
    BaseCardComponent,
    ContentCardComponent,
    SheetModalComponent,
    WalletSelectorComponent,
    BuildCmcUrlPipe,
    DesktopSidepanelComponent,
    AccountNumberComponent,
    ErrorControlPipe
  ],

  imports: [
    IonicModule,
    CommonModule,
    TranslocoPipe,
    ReactiveFormsModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    UserHeaderComponent,
    WalletValueSectionComponent,
    EvolutionCardComponent,
    WalletActionsSectionComponent,
    ContactCardComponent,
    WalletPerformanceTableComponent,
    ActivityFeedComponent,
    ItemListComponent,
    WalletCompositionChartComponent,
    BaseCardComponent,
    BaseListComponent,
    SheetModalComponent,
    ContentCardComponent,
    WalletSelectorComponent,
    DesktopSidepanelComponent,
    AccountNumberComponent,
    BuildCmcUrlPipe,
    ErrorControlPipe
  ]
})
export class UiUtilsModule {}
