import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sheet-modal',
  templateUrl: './sheet-modal.component.html',
  styleUrls: ['./sheet-modal.component.scss'],
})
export class SheetModalComponent {

  @Input({ required: true }) title!: string;
  @Input({ required: true }) isModalOpen: boolean = false;
  @Output() isModalOpenChange = new EventEmitter<boolean>();

  onClose() {
    this.isModalOpen = false;
    this.isModalOpenChange.emit(this.isModalOpen);
  }
}
