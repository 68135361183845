 <ng-container *ngIf="!busy; else loadingState">
  <ng-container *ngIf="link; else hrefTemplate">
    <ion-item [routerLink]="link" [button]="link || clickable" [detail]="link || clickable" color="light" lines="none" [ngClass]="{ 'leading-10': sizeLarge }">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </ion-item>
  </ng-container>

  <ng-template #hrefTemplate>
    <ion-item [href]="href" [button]="href || clickable" [detail]="href || clickable" color="light" lines="none" [ngClass]="{ 'leading-10': sizeLarge }">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </ion-item>
  </ng-template>
</ng-container>

<ng-template #itemContent>
  <div class="h-10 w-12 flex items-center justify-center text-center mr-4" *ngIf="iconPath || iconText || iconPicture">
    <ion-icon *ngIf="iconPath" [color]="iconColor" [src]="iconPath" [ngStyle]="{ 'fontSize': iconSize }"></ion-icon>
    <ion-label *ngIf="iconText" [ngStyle]="{ 'color': iconTextColor, 'fontSize': iconTextFontSize }">{{ iconText }}</ion-label>
    <img *ngIf="iconPicture" class="w-10 h-10 rounded-full" [src]="iconPicture" />
  </div>
  <ion-label class="ml-4 truncate">
    <h2 *ngIf="subtitle" class="text-xl" [ngClass]="{'leading-none': !sizeLarge}">{{subtitle}}</h2>
    <div class="text-gray-500 text-sm">
      <ion-label *ngIf="title" [color]="titleColor" class="overflow-anywhere" [ngClass]="{'leading-none': !sizeLarge}">{{title}}</ion-label>
    </div>
  </ion-label>
  <ion-note *ngIf="note" slot="end" [color]="noteColor" class="font-bold m-auto text-base p-0">{{note}}</ion-note>
</ng-template>

<ng-template #loadingState>
  <ion-item color="light" lines="none">
    <div class="h-10 w-12 flex items-center justify-center">
      <ion-skeleton-text [animated]="true"></ion-skeleton-text>
    </div>
    <ion-label class="ml-4">
      <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true"></ion-skeleton-text>
    </ion-label>
  </ion-item>
</ng-template>
