<div id="content" *ngIf="user" class="pt-6 h-full flex flex-col">
  <app-user-header [avatar]="avatarUrl"   [title]="'HOME.TITLE' | transloco: user" [subtitle]="'HOME.SUBTITLE' | transloco"></app-user-header>
  <ion-card color="light" class="mt-12">
    <app-wallet-selector />
  </ion-card>
  <div class="grid grid-col-1 gap-1 pt-8 px-2">
    <ion-button class="text-left ion-color-light" [class]="navItem.isActive ? '': 'text-dark'" [fill]="navItem.isActive ? 'clear' : 'default'"  *ngFor="let route of routes"  [routerLink]="route.link" routerLinkActive #navItem="routerLinkActive">
      <ion-icon class="pr-2" slot="icon-only" aria-hidden="true" [src]="route.icon"></ion-icon>
      <span class="w-full ion-text-left ion-color-light">{{route.key | transloco}}</span>
    </ion-button>

  </div>
  <div class="mt-auto p-2">
    <ion-button (click)="toggleConfirmLogout()" class="w-full" color="danger" fill="clear">{{"USER.LOGOUT" | transloco}}</ion-button>
  </div>
</div>
