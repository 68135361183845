import {Component, OnInit, ViewChild} from '@angular/core';
import {Client} from "@mlc/types";
import {DEFAULT_AVATAR} from "../../core/constants/defaultAvatar";
import {EvolutionCardComponent} from "../evolution-card/evolution-card.component";
import {UserService} from "../../core/services/user/user.service";
import {filter} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ROUTES} from "../../core/constants/routes";
import {RouteModel} from 'src/app/core/models/route.model';

@UntilDestroy()
@Component({
  selector: 'app-desktop-sidepanel',
  templateUrl: './desktop-sidepanel.component.html',
  styleUrls: ['./desktop-sidepanel.component.scss'],
})
export class DesktopSidepanelComponent  implements OnInit {
  public user!: Client;
  public busy: boolean = true;
  private defaultAvatar: string = DEFAULT_AVATAR;
  public routes: RouteModel[] = ROUTES;
  @ViewChild(EvolutionCardComponent) evolutionCardComponent!: EvolutionCardComponent;
  constructor(
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService
      .get()
      .pipe(
        filter((user?: Client) => user != undefined),
        untilDestroyed(this)
      )
      .subscribe({
        next: (user?: Client) => {
          if (!user) {
            throw Error('User disconnected');
          }
          this.user = user;
          this.busy = false;
        },
      });
  }

  get avatarUrl(): string | undefined {
    if (this.user.profilePicture?.url) {
      return this.user.profilePicture?.url;
    }
    return this.defaultAvatar;
  }

  toggleConfirmLogout() {
    this.userService.confirmLogout();
  }
}
