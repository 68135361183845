  <ion-toolbar >
    <div class="flex items-center gap-2 mr-4 ml-4">
      <img *ngIf="avatar" class="h-10 w-10 rounded-3xl object-cover" alt="User's profile Image" [src]="avatar"/>
      <div>
        <ion-title size="large" class="text-2xl">
          {{title}}
          <br/>
          <p class="text-sm text-gray-500">{{subtitle}}</p>
        </ion-title>
      </div>
    </div>
    <ion-buttons slot="end" class="m-auto" *ngIf="hasAction">
      <ion-button color="medium" (click)="toogleAction.emit()">
        <ion-icon name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
