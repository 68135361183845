import {RouteModel} from "../models/route.model";

export const ROUTES: RouteModel[] = [
  {
    key: "HOME.TAB_TITLE",
    icon: "assets/icons/home.svg",
    link: "/home",
  },
  {
    key: "WALLET.TAB_TITLE",
    icon: "assets/icons/wallet.svg",
    link: "/wallet",
  },
  {
    key: "INFORMATION.TAB_TITLE",
    icon: "assets/icons/user.svg",
    link: "/information",
  }
]
