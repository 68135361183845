<div (click)="toggleModal()" class="flex items-center px-3 py-2 md:cursor-pointer ripple-parent rounded-rectangle ion-activatable" [ngClass]="{ 'pointer-events-none': productWallets!.length < 2 }">
  <ion-ripple-effect></ion-ripple-effect>
  <img *ngIf="selectedWalletLogo" [src]="selectedWalletLogo" class="w-10 h-10 mr-2 rounded-full" />
  <ion-label class="truncate !text-xl font-bold">{{ selectedWalletName }}</ion-label>
  <ion-icon *ngIf="productWallets!.length > 1" name="chevron-expand-outline" class="text-xl ml-auto"></ion-icon>
</div>

<app-sheet-modal
  [title]="'WALLET.SELECT.TITLE' | transloco"
  [(isModalOpen)]="isModalOpen"
>
  <p class="text-gray-500 m-4 whitespace-pre-wrap">{{ 'WALLET.SELECT.DESCRIPTION' | transloco }}</p>
  <app-base-list [hideTitle]="true">
    <app-item-list
      (click)="selectedWalletOption(wallet)"
      *ngFor="let wallet of this.productWallets"
      [subtitle]="wallet.product.name"
      [iconPicture]="wallet.product.productLogo?.url"
      [clickable]="true"
      [sizeLarge]="true"
    >
    </app-item-list>
  </app-base-list>
</app-sheet-modal>
