import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user/user.service';
import { FormControl } from '@angular/forms';
import { ContractService } from '../../core/services/wallet/wallet.service';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Client, UserContract } from '@mlc/types';

@UntilDestroy()
@Component({
  selector: 'app-wallet-selector',
  templateUrl: './wallet-selector.component.html',
  styleUrls: ['./wallet-selector.component.scss'],
})
export class WalletSelectorComponent  implements OnInit {

  public productWallets?: (UserContract & { id: number })[];
  public selectedWalletControl!: FormControl;
  public isModalOpen: boolean = false;
  public selectedWalletName: string | undefined;
  public selectedWalletLogo: string | undefined;

  constructor(private userService: UserService, private contractService: ContractService) {}

  ngOnInit() {
    this.selectedWalletControl = new FormControl(null);
    this.selectedWalletControl.valueChanges.subscribe((walletId) => {
      this.contractService.loadProduct(walletId);
      this.contractService.setCurrentWallet(walletId);
    });

    this.userService
      .get()
      .pipe(
        untilDestroyed(this),
        filter((user?: Client) => user != undefined)
      )
      .subscribe((user: Client | undefined) => {
        this.productWallets = user!.productWallets || [];
        if (this.productWallets.length) {
          const defaultSelectedWallet: number = this.productWallets[0].id;

          this.contractService.getCurrentWallet().then((currentWalletId) => {
            const selectedWalletId: number = currentWalletId ?? defaultSelectedWallet;
            const selectedWallet: (UserContract & {id: number;}) | undefined = this.productWallets?.find(
              (wallet) => wallet.id === selectedWalletId
            );
            this.selectedWalletOption(selectedWallet!, false);
          });
        }
      });
  }

  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  selectedWalletOption(selectedWallet: UserContract & { id: number; }, toggleModal: boolean = true) {
    this.selectedWalletLogo = selectedWallet.product.productLogo?.url;
    this.selectedWalletName = selectedWallet.product.name;
    this.selectedWalletControl.setValue(selectedWallet.id);
    if(toggleModal) {
      this.toggleModal();
    }
  }
}
