import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss'],
})
export class BaseListComponent {

  @Input() listTitle!: string;
  @Input() showLines: boolean = false;
  @Input() hideTitle: boolean = false;
}
