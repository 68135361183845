export const enum TimeRangeEnum {
    ONE_MONTH = "ONE_MONTH",
    THREE_MONTH = "THREE_MONTH",
    SIX_MONTH = "SIX_MONTH",
    ONE_YEAR = "ONE_YEAR",
    YEAR_TO_DATE = "YEAR_TO_DATE",
    OVER_TIME = "OVER_TIME"
}

export const TimeRangeList: TimeRangeEnum[] = [
    TimeRangeEnum.OVER_TIME,
    TimeRangeEnum.YEAR_TO_DATE,
    TimeRangeEnum.ONE_YEAR,
    TimeRangeEnum.SIX_MONTH,
    TimeRangeEnum.THREE_MONTH,
    TimeRangeEnum.ONE_MONTH,
]