import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { canActivate } from './core/guards/auth.guard';
import { RolesEnum } from '@mlc/types';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [canActivate],
    data: {
      allowedRoles: [RolesEnum.CLIENT],
    },
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
