import {Component, NgZone, OnInit} from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from "@angular/router";
import {Client} from "@mlc/types";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {UserService} from "./core/services/user/user.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public user: Client | undefined = undefined;
  public isMdScreen = false;
  constructor (
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private zone: NgZone,
    private userService: UserService
  ) {
  }

  ngOnInit () {
    this.initializeApp();
    this.userService
      .get()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe({
        next: (user: Client | undefined) => {
          this.user = user;
        },
      });
    this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(untilDestroyed(this))
      .subscribe(
      isMd => {
        this.isMdScreen = isMd.matches
      }
    )
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
