import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent {
  @Input() avatar? :string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() hasAction = false;
  @Output() toogleAction = new EventEmitter<void>();

}
