import { Component, Input } from '@angular/core';
import { IonicColorEnum } from '@mlc/types';
@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent {
  @Input() title!: string | null;
  @Input() subtitle!: string | null;
  @Input() iconPath!: string | null;
  @Input() iconText!: string | null;
  @Input() iconTextColor!: string | null;
  @Input() iconTextFontSize!: string | null;
  @Input() iconColor!: IonicColorEnum;
  @Input() iconSize: string = '22px';
  @Input() iconPicture!: string | undefined;
  @Input() titleColor!: string;
  @Input() note!: string | null;
  @Input() noteColor!: string;
  @Input() href!: string;
  @Input() link!: (string | number)[];
  @Input() busy!: boolean;
  @Input() clickable!: boolean;
  @Input() sizeLarge: boolean = false;
}
